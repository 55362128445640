import { global } from "../constants";

export const data = {
    banner: {
        background: global.banner + "1.jpg",
        title: "FAQ",
        crumb: "FAQ"
    },
    faq: {
        title: "FAQ's",
        subtitle: "Frequently Ask Questions?",
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry the standard dummy text ever since the  when an printer took.",
        faqs: [
            {
                que: "What is GTA in transport?",
                ans: "A digital interface the person causing the movement of goods uploads the relevant information prior to the commencement of movement of goods and generates e-way bill on the GST portal. Best service are provide us. simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy."
            },
            {
                que: "Is lorry receipt mandatory?",
                ans: "A digital interface the person causing the movement of goods uploads the relevant information prior to the commencement of movement of goods and generates e-way bill on the GST portal. Best service are provide us. simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy."
            },
            {
                que: "What are e-way bills?",
                ans: "A digital interface the person causing the movement of goods uploads the relevant information prior to the commencement of movement of goods and generates e-way bill on the GST portal. Best service are provide us. simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy."
            },
            {
                que: "Can we take input on transportation?",
                ans: "A digital interface the person causing the movement of goods uploads the relevant information prior to the commencement of movement of goods and generates e-way bill on the GST portal. Best service are provide us. simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy."
            },
            {
                que: "What is unique number in e way Bill?",
                ans: "A digital interface the person causing the movement of goods uploads the relevant information prior to the commencement of movement of goods and generates e-way bill on the GST portal. Best service are provide us. simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy."
            },
            {
                que: "What is consignee copy?",
                ans: "A digital interface the person causing the movement of goods uploads the relevant information prior to the commencement of movement of goods and generates e-way bill on the GST portal. Best service are provide us. simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy."
            }
        ]
    }
}