import { global } from "../constants";

export const data = {
    banner: {
        background: global.banner + "1.jpg",
        title: "Our Services",
        crumb: "Our Services"
    },

    services: {
        title: "All services",
        subtitle: "Trusted For Our Services",
        description: " Our expertise spans the entire spectrum of logistics, from intricate air freight operations to meticulously planned road and sea shipments.",
        services: [
            {
                icon: global.icons + "pic1.png",
                serial: "01",
                title: "Air Freight",
                description: "Reaching new heights for your business- Global connections within reach with swift air shipments."
            },
            {
                icon: global.icons + "pic2.png",
                serial: "02",
                title: "Road Freight",
                description: "Your cargo's cruise down the highway, Smooth, punctual road freight you can trust."
            },
            {
                icon: global.icons + "pic3.png",
                serial: "03",
                title: "Ocean Freight",
                description: "From harbor to horizon, we navigate the world's waterways for seamless deliveries."
            },
            {
                icon: global.icons + "pic4.png",
                serial: "04",
                title: "Rail Freight",
                description: "Seamless solutions connecting your goods to global opportunities."
            },
            {
                icon: global.icons + "pic5.png",
                serial: "05",
                title: "Warehousing",
                description: "We bridge the gap, optimizing your supply chain with on the go solution and storage might."
            },
            {
                icon: global.icons + "pic6.png",
                serial: "06",
                title: "Project Cargo",
                description: "Our aim is to optimize and improve your supply chain so that we can give you the best service."
            }
        ]
    },

    estimation: {
        title: "Estimation",
        subtitle: "Starline logistics has a wide range of solutions",
        description: "No budget too big, no dream too small: Our estimations pave the way for your logistical adventure.",
        solutions: [
            {
                serial: "01",
                icon: global.eicons + "pic1.png",
                title: "Solutions and specialized",
                description: "Our aim is to optimize and improve your supply chain so that we can give you the best service"
            },
            {
                serial: "02",
                icon: global.eicons + "pic2.png",
                title: "Multiple warehouses",
                description: "We provide multiple drop off and pickup locations so you don't have to worry. And you should not face any kind..."
            },
            {
                serial: "03",
                icon: global.eicons + "pic3.png",
                title: "Tracking made easy",
                description: "A tracking number for the entire process. so that you can find the exact position. this process will help you"
            }
        ]
    },

    booking: {
        background: global.booking + "bg-map.png",
        image: global.booking + "pic1.png",
        imageBack: "",
        title: "Request A Quote",
        subtitle: "Booking For Product Transformation",
        description: "From vision to reality, one quote away. Let's craft your product's next chapter.",
        form: {
            quote: global.booking + "icon1.png",
            track: global.booking + "icon2.png"
        }
    },

    plans: {
        // title: "Our Plan",
        // subtitle: "The right price for you whoever you are",
        plans: [
            // {
            //     type: "Startup",
            //     price: "90$",
            //     benifits: [
            //         "Additional",
            //         "Warehouing",
            //         "Custos Borkerage",
            //         "Unlimited Transfer"
            //     ]
            // },
            // {
            //     type: "Standard",
            //     price: "150$",
            //     benifits: [
            //         "Additional",
            //         "Warehouing",
            //         "Custos Borkerage",
            //         "Unlimited Transfer"
            //     ]
            // },
            // {
            //     type: "Business",
            //     price: "260$",
            //     benifits: [
            //         "Additional",
            //         "Warehouing",
            //         "Custos Borkerage",
            //         "Unlimited Transfer"
            //     ]
            // }
        ]
    }
}