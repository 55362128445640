import { global } from "../constants";

export const data = {
    banner: {
        background: global.banner + "1.jpg",
        title: "About Us",
        crumb: "About Us"
    },

    whatwedo: {
        title: "What we do!",
        subtitle: "Specialist Logistics Services",
        description: "Beyond mere logistics, we engineer your success.",
        image: global.icons + "img1.png",
        works: [
            {
                icon: global.icons + "pic1.png",
                serial: "01.",
                title: "Road Freight",
                description: "Your cargo's cruise down the highway: Smooth, punctual road freight you can trust."
            },
            {
                icon: global.icons + "pic2.png",
                serial: "02.",
                title: "Shipping Freight",
                description: "From harbor to horizon, we navigate the world's waterways for seamless deliveries."
            },
            {
                icon: global.icons + "pic3.png",
                serial: "03.",
                title: "Air Freight",
                description: "Reaching new heights for your business: Global connections within reach with swift air shipments."
            }
        ]
    },

    achivements: [
        {
            count: "1120",
            title: "Warehouses  Across the world"
        },
        {
            count: "15",
            title: "Year of experience Delivering dreams"
        },
        {
            count: "40000",
            title: "Miles Minimum Weekly across the world"
        }
    ],

    testimonials: {
        title: "Respected",
        subtitle: "Clients & partners",
        clients: [
            global.logo + "/w1.png",
            global.logo + "/w2.png",
            global.logo + "/w3.png",
            global.logo + "/w4.png",
            global.logo + "/w5.png",
            global.logo + "/w6.png",
        ],
        partners: [
            {
                pic: global.testim + "pic1.jpg",
                quote: "I used to deliver my sourdough myself, praying the traffic gods wouldn't turn my bread into crumbs.Starlinelogistics road freight gets my pastries to cafes fresh and perfect, every time. They're my secret ingredient for happy customers.",
                name: "Jesse Len",
                designation: "Chef & Baker"
            },
            {
                pic: global.testim + "pic2.jpg",
                quote: "Lives depend on reliable logistics. Starlinelogistics' dedication to swift and precise delivery is our lifeline. They ensure essential medical supplies reach remote communities and disaster zones, making a tangible difference in the world, one shipment at a time.",
                name: "Dr. Giselle",
                designation: "Medical Practitional"
            },
            {
                pic: global.testim + "pic3.jpg",
                quote: "Scaling globally was a logistical nightmare. Starlinelogistics's seamless air freight solutions took the edge off, delivering our hardware to new markets at lightning speed. We're disrupting the industry, and they're keeping our supply chain ahead of the curve.",
                name: "Josephine",
                designation: "Tech CEO startup"
            },
            {
                pic: global.testim + "pic4.jpg",
                quote: "My family has been exporting soybeans for generations. We've seen it all, from unreliable ports to unpredictable weather. But since partnering with Starlinelogistics limited, our logistical worries have vanished. Their efficient road freight network gets our crops to the mills with lightning speed, maximizing our profits and keeping our farm legacy thriving.",
                name: "Penelope",
                designation: "Farm Miller"
            }
        ]
    },

    blogs: {
        title: "Our Blogs",
        subtitle: "Recent news & events",
        description: "Read through our Journey, Learn the best practices and our winning approach.",
        blogs: [
            {
                image: global.blog1 + "bg1.jpg",
                day: "02",
                month: "Aug",
                title: "At the end of the day, going forward, a new normal that has evolved from.",
                description: "Pirates and hostile situations that we have learned to live with across shores."
            },
            {
                image: global.blog2 + "l-1.jpg",
                day: "08",
                month: "Aug",
                title: "How all we deal with shipping challanges across continents.",
                description: "New development on the marine industry are quick to spread just like the waves of the ocean."
            },
            {
                image: global.blog1 + "bg3.jpg",
                day: "21",
                month: "Aug",
                title: "Going forward, a new normal that has evolved from generation.",
                description: "How often do we experience changes acrosss industry and in the maritime industry in particular?"
            }
        ]
    },

    approach: {
        title: "Company Approch",
        subtitle: "Reliable Logistic & Transport Solutions",
        yearsOfExperience: "15",
        specifications: [
            {
                icon: global.icons + "c-pic1.png",
                count: "550",
                title: "Professional Staff"
            },
            {
                icon: global.icons + "c-pic2.png",
                count: "1250",
                title: "On Time Delievery Weekly"
            },
            {
                icon: global.icons + "c-pic3.png",
                count: "350",
                title: "Technology and Media"
            }
        ]
    }
}
