import { global } from "../constants";

export const data = {
  whatwedo: {
    title: "What we do!",
    subtitle: "Specialist Logistics Services",
    description: "Beyond mere logistics, we engineer your success.",
    image: global.icons + "img1.png",
    works: [
      {
        icon: global.icons + "pic1.png",
        serial: "01.",
        title: "Road Freight",
        description: "Your cargo's cruise down the highway: Smooth, punctual road freight you can trust."
      },
      {
        icon: global.icons + "pic2.png",
        serial: "02.",
        title: "Shipping Freight",
        description: "From harbor to horizon, we navigate the world's waterways for seamless deliveries."
      },
      {
        icon: global.icons + "pic3.png",
        serial: "03.",
        title: "Air Freight",
        description: "Reaching new heights for your business: Global connections within reach with swift air shipments."
      }
    ]
  },

  whychooseus: {
    background: global.back + "bg-1.jpg",
    title: "Why Choose Us",
    subtitle: "We Are Professional Logistics & cargo Agency",
    description: {
      short: "Professionalism isn't just our name, it's our promise. We go beyond routine transportation, delivering expertise, reliability, and personalized solutions for every shipment.",
      long: "Forget the mundane, routine shipping experiences. At Starline Logistics Limited, we're a team of seasoned logistics professionals fueled by a passion for precision and driven by a promise to exceed expectations. We don't just move cargo, we move possibilities, seamlessly connecting your business to the world.Our expertise spans the entire spectrum of logistics, from intricate air freight operations to meticulously planned road and sea shipments. We're your one-stop shop for any cargo, no matter how delicate, bulky, or time-sensitive. Our comprehensive solutions are tailor-made to fit your specific needs and budget, ensuring every shipment reaches its destination with optimal efficiency and minimal fuss.But we're not just about technical prowess. We understand that in today's fast-paced world, every minute counts. That's why we leverage cutting-edge technology and real-time tracking systems to give you complete visibility and control over your shipments. You can track your cargo's journey in real-time, making informed decisions and maintaining complete peace of mind."
    },
    pic1: global.whyChoose + "pic1.jpg",
    pic2: global.whyChoose + "pic2.jpg",
    help: {
      icon: global.whyChoose + "qt-icon.png",
      number: "(+32460230354) Call and Whatsapp"
    }
  },

  booking: {
    background: global.booking + "bg-map.png",
    image: global.booking + "pic1.png",
    imageBack: "",
    title: "Request A Quote",
    subtitle: "Booking For Product Transformation",
    description: "From vision to reality, one quote away. Let's craft your product's next chapter.",
    form: {
      quote: global.booking + "icon1.png",
      track: global.booking + "icon2.png"
    }
  },

  estimation: {
    background: global.back + "bg-2.jpg",
    title: "Estimation",
    subtitle: "Has a wide range of solutions",
    description: "No budget too big, no dream too small: Our estimations pave the way for your logistical adventure.",
    solutions: [
      {
        serial: "01",
        title: "Solutions and specialized expertise",
        subtitle: "Our aim is to optimize and improve your supply chain so that we can give you the best service"
      },
      {
        serial: "02",
        title: "Multiple warehouses",
        subtitle: "We provide multiple drop off and pickup locations so you don't have to worry. And you should not face any kind..."
      },
      {
        serial: "03",
        title: "Tracking made easy",
        subtitle: "A tracking number for the entire process. so that you can find the exact position. it will help you"
      }
    ]
  },

  testimonials: {
    title: "Respected",
    subtitle: "Clients & partners",
    clients: [
      global.logo + "/w1.png",
      global.logo + "/w2.png",
      global.logo + "/w3.png",
      global.logo + "/w4.png",
      global.logo + "/w5.png",
      global.logo + "/w6.png",
    ],
    partners: [
        {
            pic: global.testim + "pic1.jpg",
            quote: "I used to deliver my sourdough myself, praying the traffic gods wouldn't turn my bread into crumbs.Starlinelogistics road freight gets my pastries to cafes fresh and perfect, every time. They're my secret ingredient for happy customers.",
            name: "Jesse Len",
            designation: "Chef & Baker"
        },
        {
            pic: global.testim + "pic2.jpg",
            quote: "Lives depend on reliable logistics. Starlinelogistics' dedication to swift and precise delivery is our lifeline. They ensure essential medical supplies reach remote communities and disaster zones, making a tangible difference in the world, one shipment at a time.",
            name: "Dr. Giselle",
            designation: "Medical Practitional"
        },
        {
            pic: global.testim + "pic3.jpg",
            quote: "Scaling globally was a logistical nightmare. Starlinelogistics's seamless air freight solutions took the edge off, delivering our hardware to new markets at lightning speed. We're disrupting the industry, and they're keeping our supply chain ahead of the curve.",
            name: "Josephine",
            designation: "Tech CEO startup"
        },
        {
            pic: global.testim + "pic4.jpg",
            quote: "My family has been exporting soybeans for generations. We've seen it all, from unreliable ports to unpredictable weather. But since partnering with Starlinelogistics limited, our logistical worries have vanished. Their efficient road freight network gets our crops to the mills with lightning speed, maximizing our profits and keeping our farm legacy thriving.",
            name: "Penelope",
            designation: "Farm Miller"
        }
    ]
  },

  services: {
    title: "All services",
    subtitle: "Trusted For Our Services",
    description: "From air freight to road and sea, we're your one-stop shop for global logistics. We offer comprehensive, tailor-made solutions to suit any cargo, any destination, and any budget.",
    services: [
      {
        image: global.sgallery + "1.jpg",
        title: "Air Freight",
        serial: "01",
        description: "Conquering distance at the speed of sound: Your express delivery partner in the sky."
      },
      {
        image: global.sgallery + "2.jpg",
        title: "Road Freight",
        serial: "02",
        description: "Our aim is to optimize and improve your supply chain so that we can give you the best service."
      },
      {
        image: global.sgallery + "3.jpg",
        title: "Ocean Freight",
        serial: "03",
        description: "Ocean waves won't slow your flow: Global shipping solutions that weather any storm."
      },
      {
        image: global.sgallery + "4.jpg",
        title: "Rail Freight",
        serial: "04",
        description: "Iron tracks to global markets: Seamless rail freight connecting your goods to opportunities worldwide."
      },
      {
        image: global.sgallery + "5.jpg",
        title: "Warehousing",
        serial: "05",
        description: "From warehouse walls to open plains: We bridge the gap, optimizing your supply chain with on the go solutions and storage might."
      },
      {
        image: global.sgallery + "6.jpg",
        title: "Project Cargo",
        serial: "06",
        description: "Our aim is to optimize and improve your supply chain so that we can give you the best service."
      }
    ]
  },

  approach: {
    title: "Company Approch",
    subtitle: "Reliable Logistic & Transport Solutions",
    yearsOfExperience: "35",
    specifications: [
      {
        icon: global.icons + "c-pic1.png",
        count: 1900,
        title: "Professional Staff"
      },
      {
        icon: global.icons + "c-pic2.png",
        count: 25000,
        title: "On Time Delievery"
      },
      {
        icon: global.icons + "c-pic3.png",
        count: 1350,
        title: "Technology and Media"
      }
    ]
  },

  blogs: {
    title: "Our Blogs",
    subtitle: "Recent news & events",
    description: "Read through our Journey, Learn the best practices and our winning approach.",
    blogs: [
      {
        image: global.blog1 + "bg1.jpg",
        day: "02",
        month: "Aug",
        title: "At the end of the day, going forward, a new normal that has evolved from.",
        description: "New chip traps clusters of migrating tumor cells asperiortenetur, blanditiis odit."
      },
      {
        image: global.blog2 + "l-1.jpg",
        day: "08",
        month: "Aug",
        title: "How all this mistaken idea denouncing pleasure and praising pain was.",
        description: "New chip traps clusters of migrating tumor cells asperiortenetur, blanditiis odit."
      },
      {
        image: global.blog1 + "bg3.jpg",
        day: "21",
        month: "Aug",
        title: "Going forward, a new normal that has evolved from generation.",
        description: "New chip traps clusters of migrating tumor cells asperiortenetur, blanditiis odit."
      }
    ]
  },

  projects: {
    title: "Projects",
    subtitle: "Featured Projects",
    projects: [
      {
        image: global.projects + "1.jpg",
        title: "Warehousing, Distrbution",
        subtitle: "Warehouse inventory",
        caption: "Nh-16 Highway Bridge"
      },
      {
        image: global.projects + "2.jpg",
        title: "Logistics, Analytics",
        subtitle: "Minimize Manufacturing",
        caption: "Nh-16 Highway Bridge"
      },
      {
        image: global.projects + "3.jpg",
        title: "Warehousing, Distrbution",
        subtitle: "Warehouse inventory",
        caption: "Nh-16 Highway Bridge"
      }
    ]
  }
}
